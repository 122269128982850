import React from "react";
import './GetStartedSection.css';
import Coin from '../../assets/icons/coin.jpeg'
import Icoin4 from '../../assets/icons/icon4.png'
import Coins from "../Elements/Coins";
import { FaCheck, FaUserCircle, FaUserFriends } from "react-icons/fa";





const GetStartedSection =()=>{

    return(
        <section className="get-started-section">





          <div className="row">
            <div className="col-md-6">
           

            <div className="text">
            <h1><FaUserFriends/> How to opt in as a group</h1>
            <p>Group officials authorized by members need to signup for personal account as explained on the right section then follow the steps below to register group or chama account <br/>
          
                </p>
                <br/>
                <p><FaCheck/> Download and install MastaKash App</p>
                <p><FaCheck/> Agree on any mobile number of any netwowrk to be used to operate the account</p>
                <p><FaCheck/> Visit nearest MastaKash agent with national ID or Passport to complete registration</p>
                <p><FaCheck/> Start sending money like an individual will do on any mobile number, manage group finacial activities and generate financial statements</p>


            <button className="cta-btn-primary">Install MastaKash APP</button>
          
          </div>

            </div>

            <div className="col-md-6">
            <div className="text">
          <h1><FaUserCircle/>   Opt in as individual</h1>
          <p>For individuals is even easier. All you need is a smart phone, a mobile number that you own of any netwowrk and your photo ID</p>
          <br/>
          <p><FaCheck/> Download and install MastaKash App</p>
          <p><FaCheck/> Register yourself and verify your identity. You can visit your nearest agent to complete the registration</p>
          <p><FaCheck/> You are good to go. Start experiencing mobile service interoperability, digital merry go rounds and participate in your group financial activities digitaly.</p>
          <p>With MastaKash, you can run digital merry go rounds with virtual members. When your day arrives, you get your money Pap! No delays or lose of money for unfullfilled members.</p>

           
           
               
              </div>

              
              </div>
          </div>
         
        
           
            
       
       
       
      </section>
    )
}

export default GetStartedSection;