import React from "react";
import './CreditCard.css';


const CreditCard =({brand='MastaKash'})=>{
    return(
        <div className="credit-card">
  <p className="card-brand">{brand}</p>
  <div className="chip"></div>
  <div className="card-number">**** **** **** 3456</div>
  <div className="card-holder">Ronald Wekesa</div>
  <div className="valid-thru">Valid Thru: 12/28</div>
</div>
    )
}

export default CreditCard;