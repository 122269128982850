import React from "react";
import  './UnlimitedSolutions.css'

const UnlimtedSolutions =()=>{

    return(
       <div className="unlimited-solutions">
         <div className="flex-heading">
            <div className='key-benfits'>
            <div className='row'>
      <div className='col-md-12'>
        <div className='subsection-wraper'>
        <h3>
            One Product, Unlimited Solutions
        </h3>
        <p>Experience
        transparent, secure, and inclusive financial management for individuals and
        groups worldwide.</p>
        </div>

      </div>
    </div>
            </div>
        

        </div>
       </div>
    )
}

export default UnlimtedSolutions;