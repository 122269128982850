import React,{useState,} from 'react';
import './VideoSection.css';

const VideoSection = () => {

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const handleVideoPlay = () => {
      setIsVideoPlaying(true);
    };
  
    const handleVideoPause = () => {
      setIsVideoPlaying(false);
    };
  
    const handleVideoEnded = () => {
      setIsVideoPlaying(false);
    };

    
    
      const handlePlayerStateChange = (event) => {
        if (event.data === window.YT.PlayerState.PLAYING) {
          setIsVideoPlaying(true);
        } else {
          setIsVideoPlaying(false);
        }
      };

    return (
      <section className="video-section">
        <div className="video-container">
          {/* Embed your video here */}
          <iframe 
            className='video'
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/_GVR3AF4O7Q" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
          />
        </div>
        <div className="overlay">
          <h2>Introducing MastaKash</h2>
        </div>
      </section>
    );
  }
  
  export default VideoSection;
