import React from "react";

import Interoperability from "../components/Sections/Interoperability";
import MerrygoSection from "../components/Sections/MerrygoSection";
import TableBankingSection from "../components/Sections/TableBankingSection";
import GetStartedSection from "../components/Sections/GetStartedSection";
import WhyMastaKash from "../components/Sections/WhyMastaKash";

const ProductsAndServices =()=>{
   return(
    <div id="services" style={{margin:'0px'}}>

        <Interoperability/>
        <MerrygoSection/>
      <TableBankingSection/>
      <GetStartedSection/>
     <WhyMastaKash/>

</div>
   )

}
export default ProductsAndServices