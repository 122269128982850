import React from "react";
import './WhyMastaKash.css';
import {FaCheckSquare } from "react-icons/fa";
import Benefit1Image from '../../assets/img/benefits1.png'
import Benefit2Image from '../../assets/img/bitcoin2.png'






const WhyMastaKash =()=>{

    return(
        <section className="why-mastakash-section">
            <div className="row">
                <div className="col-md-6 col-lg-6">
                <div className="image">
            <img src={Benefit2Image} />               
              </div>

                </div>

                <div className="col-md-6 col-lg-6">
                <div className="text">
            <h1>Why choose MastaKash?</h1>
            <p>MastaKash offers a robust set of tools and features to empower Chamas and individuals in managing their finances efficiently. 
          
                </p>
                <br/>
               <p> <FaCheckSquare/> Integration with other mobile money platforms and bank accounts  making financial transaction cheap and convinient</p>
               <p> <FaCheckSquare/> Integration with bank accounts enhancing security and banking convinience</p>
               <p> <FaCheckSquare/> Provision of mobile money accounts for Chamas, seamlessly linked to a bank account and operable through the MastaKash mobile platform</p>
               <p> <FaCheckSquare/> Transaction statements for members and groups, providing transparent and detailed financial records</p>
               <p> <FaCheckSquare/> Alerts and notifications for important financial transactions and updates</p>

          
          </div>

            </div>
            </div>
            
        
       
       
      </section>
    )
}

export default WhyMastaKash;