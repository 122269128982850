import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import  './NoInternet.css';



const NoInternet = (props)=>{
     // state variable holds the state of the internet connection
     const [isOnline, setOnline] = useState(true);
     // On initization set the isOnline state.
    useEffect(()=>{
        //setOnline(navigator.onLine)
        setOnline(true)
    },[])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

 
          // if user is online, return the child component else return a custom component
    if(isOnline){
        return(
            props.children
        )
        } else {
            return(
                <div className="container">
                <div className='message-wrapper'>
                   
      
      <h5 className="lead text text-danger">Oops, the internet went out.</h5>
      <img src="noconnection.png" alt="No Internet Connection"/>
      <p>What you are trying to do requires internet connection</p>
      <p id='network-status-text'>Please check your internet connection <span className='no-network'><Link to="">And try again.</Link></span></p>
    </div>
            </div>
            )
        }
      
    
}
export default NoInternet;