import React from "react";
import './Interoperability.css';
import MastakashApp from '../../assets/img/hero.png'






const Interoperability =()=>{

    return(
        <section className="interoperability-section">

          <div className="row">
            <div className="col-md-6 col-lg-6">
            <div className="text">
            <h1>Enjoy full interoperability of mobile money service with <span className="orange-text">MASTAKASH</span></h1>
            <p>MastaKash is transforming how  individuals and groups send money and make payments. You now send money on any mobile number anywhere. You no longer have to carry with you multiple SIM cards. <br/>
          
                </p>
                <p>  We are eliminating cross-platform mobile money constrains and linking informal banking sector to mainstream banking ecosystem.</p>

            <button className="cta-btn-primary">Install MastaKash APP</button>
          
          </div>

            </div>

            <div className="col-md-6 col-lg-6">
            <div className="image">
            <img src={MastakashApp}/>
           
           
           
               
              </div>

          </div>
          </div>
            
       
       
       
      </section>
    )
}

export default Interoperability;