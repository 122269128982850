import React from "react";
import  './UnlimitedSolutions.css'

const MastaKashGlobal =()=>{

    return(
       <div className="unlimited-solutions">
         <div className="flex-heading">
            <div className='key-benfits'>
            <div className='row'>
      <div className='col-md-12'>
        <div className='subsection-wraper'>
        <h3>
           Unlock the true potential of MastaKash where borders are no longer barriers
        </h3>
        <p>Our revolutionary platform allows individuals from around the world to participate in financial activities, transcending geographical limitations. With MastaKash, you can connect with a global community and experience the power of collective lending in your local currency</p>
        </div>

      </div>
    </div>
            </div>
        

        </div>
       </div>
    )
}

export default MastaKashGlobal;