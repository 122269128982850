import React from "react";
import './TableBankingSection.css';
import Icoin4 from '../../assets/icons/icon4.png'
import TableBankingImage from '../../assets/img/crypto.png'





const TableBankingSection =()=>{

    return(
        <section className="table-banking-section">
          <div className="row">
            <div className="col-md-7 col-lg-7">
            <div className="text">
            <h1>Experience a transparent, fair and Smater way of running table banking in your group.</h1>
            <p><span className="orange-text">MastaKash</span> revolutionizes table banking by offering virtual participation in group meetings, seamless savings, loan applications, loan disbursements, loan repayments and dividend distributions.  <br/>
            This boosts group member contributions, enhances financial growth for both the group and its individual members, and fosters a thriving community
                </p>

            <p>  Moreover, members have the flexibility to participate in multiple groups, even if they have conflicting meeting schedules. Meetings can be conveniently scheduled after work hours, allowing members to maximize their productivity.</p>

            <button className="cta-btn-primary">Install MastaKash APP</button>

          </div>

            </div>

            <div className="col-md-7 col-lg-5">
            <div className="image">
            <img src={TableBankingImage}/>
           
           
           
               
              </div>

            </div>
          </div>
            
      {/*}  <div className="content">
       
          <div className="text">
            <h1>Experience a transparent, fair and Smater way of running table banking in your group.</h1>
            <p><span className="orange-text">MastaKash</span> revolutionizes table banking by offering virtual participation in group meetings, seamless savings, loan applications, loan disbursements, loan repayments and dividend distributions.  <br/>
            This boosts group member contributions, enhances financial growth for both the group and its individual members, and fosters a thriving community
                </p>

            <button className="cta-btn-primary">Install MastaKash APP</button>
            <p>  Moreover, members have the flexibility to participate in multiple groups, even if they have conflicting meeting schedules. Meetings can be conveniently scheduled after work hours, allowing members to maximize their productivity.</p>
          </div>

          <div className="image">
            <img src={TableBankingImage}/>
           
           
           
               
              </div>
        

          
              

          </div>*/}
       
       
      </section>
    )
}

export default TableBankingSection;