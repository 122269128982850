import React, { useState,useEffect } from 'react';
import './Faqs.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress } from '@material-ui/core';
import {  makeGetRequest } from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';
import { Helmet } from "react-helmet-async";



const FAQs =()=>{
    const [faqs,setFaqs] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null);

    const getFaqs = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('faqs');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
            setFaqs(res.data)        
            setIsLoading(false);
            setServerError(null)

        }
       
    }

    useEffect(()=>{
       // getFaqs();
    },[])

    if(isLoading){
        return <CircularProgress/>
    }
    if(serverError){
        return <p className='text text-danger'>{serverError}</p>
    }

    return (
        <>
               <Helmet>
    <meta name="description" content=" Rosh Church management and accounting Software  frequently asked questions.
    "/>
     <link rel='canonical' href='/faqs'/>


  </Helmet>
  <div id='faqs'>
        
          
        {
             faqs.length !== 0 ?
             <div className="container">
             <br/><br/>
             <h1>Frequently Asked Questions</h1>
             <br/>
             <div className='row'>
             {
                                                 faqs.map((faq) => {
                                                     return <div className='col-sm-12 col-md-6 col-lg-6' key={faq.id} >
                                                        
                                                         <Accordion className="faq">
                                                             <AccordionSummary
                                                                 expandIcon={<ExpandMoreIcon />}
                                                                 aria-controls="panel1a-content"
                                                                 id="panel1a-header"
                                                             >
                                                                 <Typography className="faq-title">{faq.question }</Typography>
                                                             </AccordionSummary>
                                                             <AccordionDetails>
                                                                 <Typography>
                                                                     <div className='row'>
                                                                         <div className="col-md-10 ">
                                                                             {faq.answer}
                                                                         </div>
                                                                    
                                                                     
                                                                         

                                                                     </div>


                                                                 </Typography>
                                                             </AccordionDetails>
                                                         </Accordion>
                                                     
                                                        </div>
                                                 })
                                             }

             </div>
           
             

         </div>
             :
             <div>
                <p className='text text-info'>The list is empty</p>
           

         </div>
        }
        </div>
        </>
    )

}

export default FAQs