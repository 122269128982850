import React,{useEffect} from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
//import Header from "../components/Sections/Header";
//import Blog from "../components/Sections/Blog";
//import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact"
import Footer from "../components/Sections/Footer"
import FAQs from "../components/Sections/FAQs"
import About from "../components/Sections/About";
import Home from "./Home";
import ProductsAndServices from "./ProductsAndServices";
import Solutions from "./Solutions";
import FoundersStory from "../components/Sections/FoundersStory";



export default function Landing() {
  useEffect(() => {
    const sections = document.querySelectorAll('.section');
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        }
      });
    });

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      // Cleanup the observer when the component unmounts
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures the effect runs only once after the component mounts

  return (
    <>
      <TopNavbar />
      <br/><br/><br/>
      <Home className="section animated-section"/>
      <ProductsAndServices className="section animated-section"/> 
      <Solutions className="section animated-section"/>
      {/*<FAQs className="section animated-section"/>*/}
      <About className="section animated-section"/>
     {/* <Contact className="section animated-section"/>*/}   
     <FoundersStory/>
      <Footer />
    </>
  );
}


