import React from "react";
// Components
import FullButton from "../Buttons/FullButton";
// Assets

import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { BASE_URL , IMAGE_BASE_URL} from "../../constants/paths";
import { Link, Navigate,useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import './Hero.css'
import SmartPhone from "../Elements/SmartPhone";
import CreditCard from "../Elements/CreditCard";


const Hero =()=>{
    return(
        <section className="hero-section">
          <div className="row">
            <div className="col-md-6 col-lg-6">
            <div className="text">
                <h1>MastaKash is Helping Groups and Individuals streamline their Financial Management</h1>
                <p>We are transforming how Chamas operate and manage their finances, eliminating cross-platform mobile money constrains and linking informal banking sector to mainstream banking ecosystem.</p>

                <button className="cta-btn-primary">Install MastaKash APP</button>
              </div>

            </div>

            <div className="col-md-6 col-lg-6">
            <div className="image">
                <SmartPhone className="smart-phone-position"/>
                <div className="credit-card-wrapper">
                <CreditCard/>

                </div>
               
                   
                  </div>

        </div>
          </div>
            
         
           
          </section>
    )
}

export default Hero;
