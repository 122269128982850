import React from "react";


import ChamasSection from "../components/Sections/ChamasSection";
import IndividualsSection from "../components/Sections/IndividualsSection";
import MastaKashGlobal from "../components/Sections/MastaKashGlobal";
import Benefits from "../components/Sections/Benefits";
import UnlimtedSolutions from "../components/Sections/UnlimitedSolutions";

const Solutions =()=>{
   return(
    <div id="solutions" style={{margin:'0px'}}>
       <UnlimtedSolutions/>
       <ChamasSection/>
      <IndividualsSection/>
      <MastaKashGlobal/>
      <Benefits/>

</div>
   )

}
export default Solutions