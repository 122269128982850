import React, { useRef, useState } from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

// Assets

import ContactIMage from "../../assets/img/contactus.jpg";
import CompanyContactImage from "../../assets/img/contact.jpeg";
import AddressImage from "../../assets/img/nairobiaddress.png";
import { makePostRequest } from "../../services/httpHelper";
import ErrorHandler from "../../services/ErrorHandler";
import { isEmail,isEmpty,isMobile } from "../../utils/formValidator";
import { CircularProgress } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import { RECAPTCHA_SITE_KEY } from "../../constants/config";


const grecaptchaObject = window.grecaptcha // You must provide access to the google grecaptcha object.


var validationErrors ={};

export default function Contact() {
  const [errors,setErrors] = useState({});
  const [isLoading,setIsLoading] = useState(false);
  const [serverError,setServerError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [values, setValues] = useState({
    contactName:'',
    email:'',
    phone:'',
    message:'',
    subject:''
    
  
  });
  
   
  
    const handleChange = (e) =>{
      setValues({
          ...values,
         
          [e.target.name]:e.target.value
      })
  }

  const recaptchaRef = useRef();

  const[isLoginButtonDisabled,setIsLoginButtonDisabled]=useState(false)
  
  
    const handleRecaptcha = (value)=>{
      setIsLoginButtonDisabled(true)
    
  
    }


const sendMessage =async(e)=>{
 // e.preventDefault();
  setServerError(null)
  setIsSubmitting(true)
  setErrors({})
  if(isEmpty(values.contactName)){
    validationErrors.contactName ='Enter your full name'
  }
      
   if(values.contactName.length < 5){
    validationErrors.contactName ='Name is too short'
    
  }
   if(isEmpty(values.email)){
    validationErrors.email='Enter your email address'
    
  }
   if(!isEmail(values.email)){
      validationErrors.email='Invalid Email Address'
  }

  if(isEmpty(values.subject)){
 validationErrors.subject='Please enter subject';
}

if(isEmpty(values.phone)){
validationErrors.phone ='Enter your mobile number';
}

if(!isMobile(values.phone)){
  validationErrors.phone='Invalid mobile number'

}

   if(isEmpty(values.message)){
    validationErrors.message='Enter your message'
    
  }
   if(values.message.length < 10){
    validationErrors.message='Message is too short'
  }
   setErrors(validationErrors)
   //prevent data processing until all verification errors are fixed

   if(Object.keys(errors).length !==0){
    setIsSubmitting(false)
      return;
   }
     
   //   const captchaToken = await recaptchaRef.current.getValue();
//recaptchaRef.current.reset();
const data ={
  contactName:values.contactName,
  email:values.email,
  message:values.message,
  subject:values.subject,
  phone:values.phone,
  //captchaToken:captchaToken
}
      const res = await makePostRequest('contact-us',data);
      if (res && res.errorType) {
          setIsSubmitting(false);
          const errorMessage = ErrorHandler(res)
          setServerError(errorMessage)

      } else {
         
        
          setIsSubmitting(false);
          setServerError(null);
          setSubmitted(true)

      }
  
}



  return (
    <>

<Helmet>
    <meta name="description" content=" Have questions or need assistance? Our team is here to help. Contact us today"/>
     <link rel='canonical' href='/contact-us'/>


  </Helmet>
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
            Have questions or need assistance? Our team is here to help.<br/> Contact us today.
              <br />
           
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
              {isSubmitting && <CircularProgress/>}
              {submitted && <p className="text text-success">Thank you for contacting us {values.contactName}. We shall get back to you as soon as possible; usually within 24 hours.</p> }
              {serverError !==null && <p className="text text-danger">{serverError}</p>}

                <label className="font13">Full Name:</label>
                <input type="text" id="fname" name="contactName" className="font20 extraBold" value={values.contactName} onChange={handleChange}/>
                {errors.contactName && <p className="text text-danger">{errors.contactName}</p>}
                <label className="font13">Email:</label>
                <input type="text" id="email" name="email" className="font20 extraBold" value={values.email} onChange={handleChange}/>
                {errors.email && <p className="text text-danger">{errors.email}</p>}

                <label className="font13">Mobile Phone Number:</label>

                <input type="text" id="phone" name="phone" className="font20 extraBold" value={values.phone} onChange={handleChange}/>
                {errors.phone && <p className="text text-danger">{errors.phone}</p>}

                <label className="font13">Subject:</label>
                <input type="text" id="subject" name="subject" className="font20 extraBold" value={values.subject} onChange={handleChange}/>
                {errors.subject && <p className="text text-danger">{errors.subject}</p>}

                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" value={values.message} onChange={handleChange}/>

                {
							
              <ReCAPTCHA
          ref={recaptchaRef }
          sitekey={RECAPTCHA_SITE_KEY}
          grecaptcha={grecaptchaObject}
          onChange={handleRecaptcha}
        />
            
          }
              </Form>
              <SumbitWrapper className="flex">
                {
                  !isLoginButtonDisabled ?
                  <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} onClick={()=>sendMessage()} disabled/>
                  :
                  <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} onClick={()=>sendMessage()}/>

                }
              
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={CompanyContactImage} alt="office" className="radius6" style={{width:'180px',height:'204px'}}/>
                </ContactImgBox>
                <ContactImgBox>
                  <img src={AddressImage} alt="office" className="radius6" style={{width:'180px',height:'295px'}}/>
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactIMage} alt="office" className="radius6" style={{width:'278px', height:'330px'}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
    
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #008080;
  background-color: #008080;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #008080;
    border: 1px solid #008080;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









