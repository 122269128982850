import React,{useState,useEffect} from 'react';
import './About.css';
import { makeGetRequest } from '../../services/httpHelper';
import  ErrorHandler  from '../../services/ErrorHandler';
import moveMoney from '../../assets/img/benefits1.png'
import secureImage from '../../assets/img/benefits2.png'
import { CircularProgress } from '@material-ui/core';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import MoneyTransfer from '../../assets/img/money_transfer.jpg'
import RonaldPhoto from '../../assets/img/ronaldpassphoto.png'
import AntonyPhoto from '../../assets/img/antonypassport.jpg'

import NetworkImage from '../../assets/img/network.png'
import ProfitImage from '../../assets/img/profit.png'
import CashbackImage from '../../assets/img/cash-back.png'







const About = ()=> {
        const [profile, setProfile] = useState([]);
        const [propositions,setPropositions] = useState([]);
        const [isLoading,setIsLoading] = useState(false);
        const [serverError,setServerError] = useState(null)
        const [showFoundersStory,setShowFoundersStory] = useState(false)
        const [showCoFoundersStory,setShowCoFoundersStory] = useState(false)



        const toggleFoundersStory =()=>{
            setShowFoundersStory(!showFoundersStory)

        }

        const toggleCoFoundersStory =()=>{
            setShowCoFoundersStory(!showCoFoundersStory)

        }


        useEffect(()=>{
        const getCompanyProfile = async()=>{
            setIsLoading(true);
            const res = await makeGetRequest('get-company-profile');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
    
            } else {
                setProfile(res.data.profile[0]);
                setPropositions(res.data.propositions);

    
                setIsLoading(false);
                alert(profile.vision)
    
            }
           
        }
      //  getCompanyProfile();
        },[])
   
        return (
           <div className='about-section ' id="about">
           {isLoading ? <CircularProgress/>
            :
                <div className='container'>
                   
                   
                    <div className='wraper'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <h2>About MastaKash</h2>
                                <p>MastaKash is a fintech startup that is revolutionizing the mobile money industry by providing all-in-one, secure, and inclusive platform for individuals and groups to send, receive, and manage money globally</p>
                                <p>
                                With focus on financial inclusivity, interoperability of mobile money service and innovation; MastaKash is transforming how Chamas operate and manage their finances, eliminating cross-platforms mobile money constrains and linking informal banking sector to mainstream banking ecosystem.
                                </p>

                                

                            </div>
                            <div className='col-md-5'>
                                <br/><br/><br/>
                            <img src={MoneyTransfer} width='80%' height='50%' className='image-right'/>

                                </div>
                        </div>


                        <div className='row'>
                          
                            <div className='col-md-5'>
                                <br/><br/><br/>
                            <img src={ProfitImage} width='80%' height='50%' className='image-right'/>

                                </div>

                                <div className='col-md-7'>
                                <h3>Company</h3>
                                <p>MastaKash is a registered company in Kenya, operating under the legal structure of private limited company. The company is headquartered in Nairobi.</p>
                                <p>
                                We are dedicated to empowering the unbanked and underbanked population by bridging the gap between traditional banking services and the mobile money ecosystem. Our core values include financial inclusion, innovation, social impact, and customer-centricity
                                </p>

                                

                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-md-7'>
                                <h3>Our Mission</h3>
                                <p style={{fontStyle:'italic'}}>Our mission is to empower individuals and  groups alike, making financial service a hassle-free experience for everyone </p>
                                <p>
                                Our core values are financial inclusion, innovation, social impact, and customer-centricity
                                </p>

                                

                            </div>
                            <div className='col-md-5'>
                                <br/><br/><br/>
                            <img src={NetworkImage} width='80%' height='80%' className='image-right'/>

                                </div>
                        </div>


                        <div className='row'>
                          
                          <div className='col-md-5'>
                              <br/><br/><br/>
                          <img src={CashbackImage} width='80%' height='50%' className='image-right'/>

                              </div>

                              <div className='col-md-7'>
                              <h3>Products and Services</h3>
                              <p>MastaKash provides a range of essential financial services designed to meet the unique needs of Chamas and individuals in the informal banking sector. These services include:</p>
                             
                             <p><FaCheck /> <strong className='orange-text'>Mobile Money Services </strong>: <br/>Money Transfer and Payments acrossplatforms and acros-border</p>
                             <p><FaCheck /> <strong className='orange-text'>Assistance in running table banking for Chamas</strong> :<br/> including savings and borrowings among group members</p>
                             <p><FaCheck /> <strong className='orange-text'>Facilitation of Merry-Go-Round arrangements</strong> :<br/> Secure and Exclusive Revolving Fund</p>

                              

                          </div>
                      </div>


                                

                        

                                    
</div>
                    
          



                </div>
        }
                </div>

                

        );
    

}
export default About;