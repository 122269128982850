import React from "react";
import './ChamasSection.css';
import ChamaImage from '../../assets/img/group_banking.jpg'
import ChamaImage2 from '../../assets/img/global.png'







const ChamasSection =()=>{

    return(
        <section className="chama-section">
          <div className="row">
            <div className="col-md-6">
            <div className="text">
            <h1>How Chamas are using MastaKash.</h1>

            <ul>
                <li><strong className="orange-text">Registering and managing members</strong> - MastaKash is helping Chamas register and track financial activities of their members. These data provides Chamas with the ability to generate statements provide valuable insights for future planning and reporting purposes, and empowering communities to make informed decisions</li>

            
                <li><strong className="orange-text">Issue and Manage Loans</strong> - MastaKash is helping Chamas create loan products for their members, set loan rules, determine each members loan limits, issue loans and collect payments. This leads to fair and transparent loan promotion and distribution.</li>

                <li><strong className="orange-text">Making savings through revolving fund schemes</strong> - Innovative features of MastaKash Merry Go round enables Chamas to digitally run and manage merry go rounds. This eliminates the risk of losing money, ensures fair distribution of contributions, and provides a secure and transparent platform for savings</li>

                <li><strong className="orange-text">Equitable Dividend Distribution</strong> - MastaKash enables equitable distribution of dividends among members based on their shares. Dividends are calculated at the end of the trade period or as predetermined by the group during table banking setup. Each member receives their fair share, reinforcing transparency and rewarding active participation.</li>

                <li><strong className="orange-text">Send Money and Make Payment</strong> - With MastaKash, a group can operate a mobile money account just like an individual would. With Secure authentication mechanism in place, Chamas can now send money, make payments and withdrawal cash wether from their MastaKash wallet or from integrated Bank Account or other mobile money account. This comes with convinence, security, data and cost savings on banking activities.</li>
            
                
            
            </ul>
         

          
          </div>

            </div>

            <div className="col-md-6">
            <div className="image">

                <img src={ChamaImage2} width="400"/>                  
                  </div>
                  <button className="cta-btn-primary">Install MastaKash APP</button>


              
              
              </div>
          </div>
            
       
       
       
      </section>
    )
}

export default ChamasSection;