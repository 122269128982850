import React,{useState,useEffect} from 'react';
import './FoundersStory.css';
import { makeGetRequest } from '../../services/httpHelper';
import  ErrorHandler  from '../../services/ErrorHandler';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import RonaldPhoto from '../../assets/img/ronaldpassphoto.png'
import AntonyPhoto from '../../assets/img/antonypassport.jpg'




const FoundersStory = ()=> {
        const [showFoundersStory,setShowFoundersStory] = useState(false)
        const [showCoFoundersStory,setShowCoFoundersStory] = useState(false)



        const toggleFoundersStory =()=>{
            setShowFoundersStory(!showFoundersStory)

        }

        const toggleCoFoundersStory =()=>{
            setShowCoFoundersStory(!showCoFoundersStory)

        }


      
   
        return (
           <div className='founder-section ' id="about">
           
                <div className='container'>
                   
                   
                    <div className='wraper'>
                               


                       


                       


                      <div className='row' style={{marginTop:"30px",marginBottom:"35px"}}>
                            <div className='col-md-7'>
                                <h3>Founder's Journey-Ronald</h3>
                                <p> I'm Ronald Wekesa Wafula, and I am honoured to share my founder journey with you. As the founder of MastaKash, I am driven by a deep-rooted passion for addressing the financial disparities that plague our communities. With over a decade of experience in building high-end software solutions and a background in finance and community development, I embarked on a mission to revolutionize the financial landscape through MastaKash. <br/><span> <Link onClick={()=>toggleFoundersStory()}> {!showFoundersStory ? '..Continue Reading':'Hide Story'} </Link></span></p>
                                {
                                    showFoundersStory && 
                                    <>

              <h4 className='orange-text'>Motivation and Vision</h4>
                                <p>
                                My journey began with firsthand experiences working with Chamas (saving groups) and participating in various informal financial initiatives like mobile money, merry-go-rounds, and table banking. <br/>While these initiatives offered hope and opportunity, they were often marred by disappointments and limitations. My vision for MastaKash emerged from these experiences, fuelled by a desire to create a solution that bridges the gap between traditional banking models and the informal financial sector, empowering individuals and communities to achieve financial security and prosperity.
                                
                                </p>
                                <h4 className='orange-text'>Identifying the Problem</h4>

                                <p>The frustrations I encountered, such as losing two years' worth of savings due to the collapse of a Chama and the inability to access banking services without extensive travel, highlighted the pressing need for innovation in the financial sector. Lack of access to banking services, limited interoperability between mobile money networks, and the risk of financial losses were common challenges faced by many individuals and Chamas</p>
                                
                                <h4 className='orange-text'>Conceptualization and Development</h4>

                                <p>
                                Inspired by these challenges, I envisioned a platform that would revolutionize mobile money services, providing seamless interoperability, and empowering Chamas to operate like traditional banks without the constraints of physical branches. With my background in technology and finance, I led a dedicated team in developing MastaKash, a groundbreaking solution that would redefine financial inclusion and community development.
                                </p>

                                <h4 className='orange-text'>Key Features and Innovation</h4>
                                <p>
                                MastaKash isn't just another mobile money platform. It's a comprehensive solution that offers universal access for individuals and Chamas, regardless of their telecom provider. With features like digital merry-go-rounds, table banking, and fund drives, MastaKash addresses the real challenges faced by informal financial systems. Our platform simplifies transactions, eliminates the need for multiple phone lines or accounts, and provides secure and exclusive revolving funds, ensuring the financial empowerment of our users.
                                </p>

                                <h4 className='orange-text'>Achieving Milestones</h4>
                                <p>
                                Since its inception, MastaKash has embarked on a journey marked by significant milestones, each bringing us closer to our vision of revolutionizing the financial landscape and empowering communities worldwide. Our journey began with rigorous product development, where we meticulously crafted MastaKash to address the real challenges faced by individuals and Chamas in the informal financial sector.
                                </p>
                                <p>
                                Currently, we stand on the brink of a significant milestone as we navigate the regulatory landscape to secure the necessary licenses for MastaKash's rollout. Regulatory compliance is paramount to our mission of ensuring a secure and trusted platform for our users. We are working closely with regulatory bodies to meet all requirements and obtain the necessary approvals, demonstrating our commitment to operating with integrity and transparency.
                                </p>

                                <h4 className='orange-text'>Impact and Future Outlook</h4>
                                <p>
                                The impact of MastaKash extends far beyond transactions. It's about empowering individuals to take control of their financial futures, enabling Chamas to thrive as financial institutions, and fostering community development on a meaningful scale. As we look to the future, our vision for MastaKash is clear: to continue innovating, expanding our reach, and making a lasting difference in the lives of millions around the world.
                                  </p>
                                    </>
                                }
                               


                                

                            </div>
                            <div className='col-md-5'>
                                <div>
                                <p>Ronald Wekesa Wafula; Founder, CTO and CEO</p>
                                <div className='image'>
                                <img src={RonaldPhoto} width='80%' height='70%' className='image-right'/>

                                </div>

                                </div>
                              
                            

                                </div>
                        </div>




                        <div className='row' style={{marginTop:"30px",marginBottom:"35px"}}>
                        <div className='col-md-5'>
                               <div>
                                <div className='image'>
                                <img src={AntonyPhoto} width='80%' height='70%' className='image-right'/>


                                </div>

                               <p>Antony Luvale Shivere; Co-Founder, CMO</p>


                               </div>

                                </div>
                            <div className='col-md-7'>
                                <h3>Co-Founder's Journey -Antony</h3>
                                <p> As Co-founder to MastaKash am glad to share my journey with you. MastaKash is an idea linked to challenges faced by untargeted but significant section of population of our communities especially in Africa and many other third world countries. MastaKash idea was meant to facilitate and streamline our unique traditional models of savings, banking and transacting business.

                                <br/><span> <Link onClick={()=>toggleCoFoundersStory()}> {!showCoFoundersStory ? '..Continue Reading':'Hide Story'} </Link></span>
                                </p>
                                
                               
                               {
                                showCoFoundersStory && 
                                <>
                                <h4 className='orange-text'>Motivation and Vision</h4>
                                <p>
                                With my professional background in Cooperative Banking and Management our Cooperative Societies memberships that included both individual and group chamas paused a myriad of challenges that affected consistency in group savings and loan servicing since we had many accountability gaps that largely depended on trust and good faith amongst group members and it's leadership. <br/>Many of these groups had resulted to trading in cash partly also because they subscribed to different telcom networks which also made our interaction involve more paperwork.security and prosperity.
                                </p>
                                <h4 className='orange-text'>Identifying the Problem</h4>

                                <p>Sustainability of chama membership in our Cooperative Societies and Micro Finance institutions was quite weak as a result informal savings groups or chamas had been identified as some of the high risk clients which either limited the amounts that financial institutions could advance to them or attracted higher interest rates in order to cushion financial institutions from higher risks of default.</p>
                                
                                <h4 className='orange-text'>Conceptualization and Development</h4>

                                <p>
                                In many of our brainstorming and mitigation inhouse meetings, I always opined that mobile technology customized to accommodate these different savings groups would come a long way streamlining their operations and digitizing their records which was an integral tool our banking institutions would more reliably use to determine the credit score or what each and every individual group qualified for. Hence computing the loan would rely more on data than on a blanket or predetermined fixed loan limit that may not suitably serve their financial requirements.                                 </p>

                                <h4 className='orange-text'>Key Features and Innovation</h4>
                                <p>
                                Most of the unbanked and underbanked sections of the economy have been catered for in MastaKash. Our local models of savings and lending are categorized in small economic and social units where revolving fund or pooled resources are used as a source of credit or contributions to it's members. Some of these units are represented in our Management Information platforms like the Digital Merry Go Round, Table Banking, Funds Drive. These are some of the key features we have introduced to enhance seamless, transparent and accountable handling of members contributions and savings.                                </p>

                                <h4 className='orange-text'>Achieving Milestones</h4>
                                <p>
                                We took a position of participation and engagement with our target market niche where we have closely walked this journey with the players in this industry to understand their different models of doing business, and we also conducting our own fact finding surveys that have greatly assisted us in terms of feedback of the real and not perceived market needs or demands of our target market.                                     </p>
                                <p>
                                We also plan to pilot our product with them as we fine tune on any areas that may require any adjustments before we can formally launch MastaKash into the market.                                </p>
                                
                                </>
                               }
                                


                                

                            </div>
                           
                        </div>

                        

                                    
</div>
                    
          



                </div>
        
                </div>

                

        );
    

}
export default FoundersStory;