import React from "react";
import './SmartPhone.css'

const SmartPhone =()=>{
    return(
        <div className="smartphone">
  <div className="screen">
    <ul className="screen-trans">
      <div className="bal-container">
         
          Bal: <span>$500.00</span>
      </div>
      <li><strong>12/12/2025</strong><br/> Chama Contribution <span className="money-out"> $100.00</span></li>
      <hr/>
      <li><strong><strong>12/12/2025</strong></strong> Transaction Fee <span className="money-out"> $1.00</span></li>
      <hr/>
      <li><strong>12/12/2025</strong><br/> Table banking loan repayment <span className="money-out"> $80.00</span></li>
      <hr/>
      <li><strong>12/12/2025</strong><br/> Received Merry Go Contributions <span className="money-in"> $200.00</span></li>
      <hr/>
      <li><strong>12/12/2025</strong><br/> Send Money to Joan<span className="money-in"> $50.00</span></li>
      <hr/>
    
      <hr/>
    </ul>
  </div>
  <div className="button"></div>
  <div className="camera"></div>
</div>
    )
}

export default SmartPhone;