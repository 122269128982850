import React from 'react';
import DOMPurify from 'dompurify';

const SanitizeHtmlComponent = ({ htmlContent,maxContentLength=0 }) => {
        

  // Sanitize and render the HTML content
  const sanitizedHTML = DOMPurify.sanitize(htmlContent);
  if(maxContentLength===0){
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;

  }else{
    // Find the index of the first opening <p> tag
  const firstPTagIndex = sanitizedHTML.indexOf('<p');
  console.log(sanitizedHTML)
  const truncatedContent = firstPTagIndex !== -1
  ? sanitizedHTML.substring(firstPTagIndex, firstPTagIndex + maxContentLength) + '...'
  : sanitizedHTML.substring(0, maxContentLength) + '...';
  
   // const truncatedContent = sanitizedHTML.length > maxContentLength ? sanitizedHTML.substring(0, maxContentLength) + '...' : sanitizedHTML;
    return <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />;
  }




};

export default SanitizeHtmlComponent;