import React from "react";
import './MerrygoSection.css';
import Coin from '../../assets/icons/coin.jpeg'
import Icoin4 from '../../assets/icons/icon4.png'
import MerrygoRoundImage from '../../assets/img/merrygo_round.jpg'
import MerrygoRoundImage1 from '../../assets/img/bitcoin.png'






const MerrygoSection =()=>{

    return(
        <section className="merrygo-section container">

          <div className="row">
            <div className="col-md-6">
            <div className="text">
            <h1>Participate in Secure Merry Go Rounds alone or in a group anywhere.</h1>
            <p>Bid farewell to the risks and disappointments of traditional merry go rounds and embrace a secure and fair system that ensures every participant receives their rightful savings. <br/>
          
                </p>

            <p>  Whether you choose to join a group or create your own personal merry go round, <span className="orange-text">MastaKash </span>offers automated or manual contributions, easy withdrawals, and timely reminders to keep you on track.</p>

            <button className="cta-btn-primary">Install MastaKash APP</button>

          </div>

            </div>

            <div className="col-md-6">
            <div className="image">
            <img src={MerrygoRoundImage1}/>
           
           
           
               
              </div>
              
              </div>
          </div>
            
       
       
       
      </section>
    )
}

export default MerrygoSection;