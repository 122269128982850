import React, { useState, useEffect} from 'react';
import { Link,useLocation,useParams } from 'react-router-dom';
import SanitizeHtmlComponent from './SanitizeHtmlComponent';
import  './Post.css';
import { FaComment, FaHeart } from 'react-icons/fa';
import Moment from 'react-moment';
import { IMAGE_BASE_URL } from '../constants/paths';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import TopNavbar from '../components/Nav/TopNavbar';
import { Helmet } from 'react-helmet-async';


const Post =()=>{
    const[post,setPost]=useState(null)
    const[relatedPosts,setRelatedPosts]=useState([])

    const[error,setError]=useState(null)
    const[isLoading,setIsLoading]=useState(false)

    const {state} = useLocation();
    const {id} = useParams();

    const metaDesc = `Rosh Church management and accounting Software ${post.title}`;
    const metaLink =`/blog/${post.slug}/${id}`

    const getPost = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest(`blog-post/${id}`);
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
            setPost(res.data)        
            setIsLoading(false);
            setError(null)

        }
       
    }

    const getRelatedArticles = async()=>{
      setIsLoading(true);
      const res = await makeGetRequest(`related-posts/${id}`);
      if (res && res.errorType) {
          setIsLoading(false);
          const errorMessage = ErrorHandler(res)
          setError(errorMessage)

      } else {
          setRelatedPosts(res.data)        
          setIsLoading(false);
          setError(null)

      }
     
  }

    useEffect(()=>{
        getPost();
        getRelatedArticles();
    },[id])
    return(
     <>

<Helmet>
    <meta name="description" content={metaDesc}/>
     <link rel='canonical' href={metaLink}/>


  </Helmet>
     <TopNavbar returnTo={true}/>
     <div className='container post-wrapper' style={{fontSize:"25px"}}>
        {
            post !== null ?
            
               post.featuredImage !== null &&
               <article className="hentry">
                  <div className="featured-image">
  <img src={IMAGE_BASE_URL+post.featuredImage} alt={post.title} className='post-img'/>
</div>

<h1 className="entry-title">{post.title}</h1>
<div className="entry-meta">
  <p><span className="author">Written by <a href="#">{post.author !== null && post.author.first_name} {post.author !== null && post.author.last_name}</a></span> <span className="date"> <Moment fromNow>{post.createdAt}</Moment></span></p>
  <br/><br/><br/><br/>
  <h5>Related</h5>
  {
            relatedPosts.length !==0 &&
            relatedPosts.map((post)=>{
                return <div className='row' key={post.id} style={{marginBottom:"25px"}}>
                    <div className='col-md-10'>
                    <Link to={`/post/${post.id}`}>
                            <h4>{post.title}</h4>
                            </Link>

                        <Link to={`/post/${post.id}`}>
                        <img src={IMAGE_BASE_URL+post.featuredImage} alt={IMAGE_BASE_URL+post.featuredImage} style={{ width: '100%', height: '200px' }}/>
                        </Link>

                        </div>                   

                        <div className='col-md-4'>
                        </div>
                </div>
            })
            
        }
</div>
<div className="entry-content">
{<SanitizeHtmlComponent htmlContent={post.content} />}
</div>

<footer className="entry-footer">
  <br/>
  <div style={{display:"flex",alignItems:"right", marginLeft:"400px",marginBottom:"20px"}}>
    <div><FaHeart style={{ color:"grey", cursor:"pointer",fontSize:"25px"}}/><sup>12</sup></div>
    <div><FaComment style={{marginLeft:"100px", fontSize:"25px", cursor:"pointer",color:"grey"}}/><sup>33</sup></div>
  </div>
  
  </footer>


        </article>
               
            
            
            :
            <p className='text text-warning'>Nothing to show</p>
        }
       
      </div>
     </>
    )
}
export default Post;