import React from "react";
import Hero from "../components/Sections/Hero";
import Products from "../components/Sections/Products";
import Interoperability from "../components/Sections/Interoperability";
import VideoSection from "../components/Sections/VideoSection";

const Home =()=>{
   const sections = document.querySelectorAll('.section');
   const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        }
      });
    });
    
    sections.forEach(section => {
      observer.observe(section);
    });
   return(
    <div id="home" style={{margin:'0px'}}>
    <Hero className="section animated-section"/>
    <VideoSection className="section animated-section"/>
    <Products className="section animated-section"/>

</div>
   )

}
export default Home