import React from "react";
import './Products.css'
import { FaCreditCard, FaGlobe, FaMobileAlt, FaMoneyCheck, FaSave } from "react-icons/fa";

const Products =()=>{
    return(
       <div className="container">
       <div className="row">
        <div className="col-md-4 col-lg-4">
        <div className="product">
                <FaMobileAlt className="product-icon"/>
                <h3 className="product-title">Mobile Payment</h3>
                <p className="product-text">Send money on any mobile number anywhere. Interoperability is here with us eliminating constraints associated with cross-platform transactions</p>
            </div>

        </div>

        <div className="col-md-4 col-lg-4">
        <div className="product">
                <FaGlobe className="product-icon"/>
                <h3 className="product-title">Merry Go Round</h3>
                <p className="product-text">Run and manage Merry Go Round in a Smart way. Reach your financial saving goals without risks of losing money</p>
            </div>
            
            </div>


            <div className="col-md-4 col-lg-4">
            <div className="product">
                <FaCreditCard className="product-icon"/>
                <h3 className="product-title">Table Banking for Chamas</h3>
                <p className="product-text">Digitize table banking in groups eliminating lose of money and streamling loan distribution and repayment. And there is more..</p>
            </div>
            
            </div>
       </div>
       

       </div>
    )
}

export default Products;