import React from "react";
import './IndividualsSection.css';
import BnkingImage from '../../assets/img/banking1.jpg'





const IndividualsSection =()=>{

    return(

        <section className="individuals-section">
     
          <div className="row">

          <div className=" col-sm-12 col-md-5 col-lg-5">
           <div className="image">
           <img src={BnkingImage} />
           </div>

            </div>

            <div className=" col-sm-12 col-md-7 col-lg-7">
            <div className="text">
            <h1>How Individuals are using MastaKash.</h1>

            <ul>
                <li><strong className="orange-text">Mobile Money Service</strong> - MastaKash is helping individuals send money, make payment, and withdrawal cash across network anywhere on the world. Interoperability that MastaKash provides eliminates cross-platform mobile money transaction constrains enhancing convinience and reducing transaction costs.
                </li>

            
                <li><strong className="orange-text">Virtual Participation in Group Activities</strong> - With MastaKash, individuals who are members of the group can continue to actively participate in group activities even when they are countries away. With borderless participation; MastaKash enables individuals from different parts of the world to join table banking, and merry go rounds and participate in the lending ecosystem. </li>

                <li><strong className="orange-text">Computerized Merry Go Rounds </strong> -With computerized merry go rounds, individuals can create their own savings plans, setting their desired contribution amounts and frequency to meet their unique financial goals. At the end of the merry-go, money will be available for cash out. This model  enables individuals who do not belong to a group but want to enjoy what those in groups are enjoying. Also is a best for solution for timed financial goal savings.</li>

                <li><strong className="orange-text">Synchoronize Chama Participation</strong> - MastaKash enables individuals to partipate in multiple Chamas and manage it in one place. An Individual can make his conributions, apply for loans, earn dividends and retrieve detailed account statements in a single tap. This helps individuals  to streamline their financial management, run table banking, merry go rounds, transfer money to any mobile number and process payments delivered through an intuitive mobile platform
                </li>

      
            
                
            
            </ul>
         

          
          </div>

            </div>

          
          </div>
            
     
       
       
      </section>
    )
}

export default IndividualsSection;