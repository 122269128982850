import React from "react";
import {BrowserRouter as Router, Routes, Route,} from 'react-router-dom';
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import 'bootstrap/dist/css/bootstrap.css';
import Posts from "./screens/Posts.jsx";
import Post from "./screens/Post.jsx";
import FAQs from "./components/Sections/FAQs.jsx";
import NoInternet from "./services/NoInternet.js";


function App() {

  return(

    <NoInternet>
      <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
     
      <Router>
       
      <Routes>
      <Route exact path="/" element={<Landing/>}/>
     <Route exact path="/blog" element={<Posts/>}/>
     <Route exact path="/blog/:slug/:id" element={<Post/>}/>
     <Route exact path="/faqs" element={<FAQs/>}/>
       

      
      </Routes>
  
    </Router>
      
      
      </>


    </NoInternet>

  );

  
 
}

export default App;

